import {
	getRequest,
	postRequest
} from "@/http/http.js"

// 设备管理表格
export const deviceListInfo = query => postRequest('/admin/deviceListInfo', query);

// 设备状态下拉框
export const deviceStateOption = query => postRequest('/admin/deviceStateOption', query);

// 设备应用下拉框
export const deviceCateOption = query => postRequest('/admin/deviceCateOption', query);

// 所属公司下拉框
export const sysCompanyOptionList = query => postRequest('/admin/sysCompanyOptionList', query);

// 设备管理详情
export const deviceDetails = query => postRequest('/admin/deviceDetails', query);

// 设备应用分类表格
export const deviceCateListInfo = query => postRequest('/admin/deviceCateListInfo', query);

// 新增设备应用分类
export const deviceCateInfoAdd = query => postRequest('/admin/deviceCateInfoAdd', query);

// 删除设备应用分类
export const deviceCateInfoDel = query => postRequest('/admin/deviceCateInfoDel', query);

// 报警日志表格
export const deviceAlarmLog = query => postRequest('/admin/deviceAlarmLog', query);

// 油脂消耗表格数据
export const deviceGreaseListInfo = query => postRequest('/admin/deviceGreaseListInfo', query);

// 油脂消耗导出
export const deviceGreaseListInfoImport = query => getRequest('/admin/deviceGreaseListInfoImport', query);
