<template>
	<div class="bigBox">
		<h2>设备应用分类</h2>
		<div class="">
			<div class="iptsBox margin-top20">
				<el-form ref="form" :model="form" label-width="80px">
					<div class="flex-sb">
						<div class="row-me">
							<el-input v-model="form.keyword" prefix-icon="Search" placeholder="设备应用名称"
								class="namePut"></el-input>
							<el-button type="primary" icon="Search" class="searchs margin-right10" @click="search">
								搜索</el-button>
							<el-button class="agains" @click="resetBtn">重置
							</el-button>
						</div>
						<div class="row-me row-center addDiv" @click="handleClickEdit"
							v-if="this.powerListName.includes('新增按钮')">
							<img src="../../assets/img/icon_xz.png" class="iconImg" />&nbsp;
							<span class="youzhi">新增设备应用</span>
						</div>
					</div>
				</el-form>
			</div>
			<div class="tabsBox margin-top20">
				<el-table ref="singleTable" :data="arrangeTabList" border header-cell-class-name="table-header-style"
					cell-class-name="table-cell-style">
					<el-table-column type="index" label="序号" width="70" align="center">
					</el-table-column>
					<el-table-column prop="name" label="设备应用名称" align="center">
					</el-table-column>
					<el-table-column prop="remarks" label="描述" align="center">
					</el-table-column>
					<el-table-column label="操作" align="left" width="140">
						<template #default="scope">
							<el-button type="text" size="small" v-if="this.powerListName.includes('编辑按钮')"
								@click="handleClickEdit(scope.row)">编辑
							</el-button>
							<el-popconfirm title="确定删除吗?" @confirm="handleClickDel(scope.$index, scope.row)"><template
									#reference>
									<el-button type="text" size="small"
										v-if="this.powerListName.includes('删除按钮')">删除</el-button>
								</template>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
				<div class="row-me row-center flex-end1 margin-top10" v-if="arrangeTabList.length>0">
					<el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
						:current-page.sync="pageNo" :total="total">
					</el-pagination>
				</div>
			</div>

		</div>
		<!-- 新增设备应用 -->
		<el-dialog v-model="dialogaddArrange" :title="dialogTitle" width="500" draggable>
			<!-- <el-form :model="forms" label-width="70px" label-position="right"> -->
			<el-form :model="forms" label-position="right">
				<div class="leftDiv">
					<el-form-item label="应用名称">
						<el-input v-model="form.name" placeholder="请输入" />
					</el-form-item>
				</div>

				<div class="leftDiv">
					<el-form-item label="描述">
						<el-input v-model="form.remarks" type="textarea" />
					</el-form-item>
				</div>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogaddArrange = false" class="btns margin-right10">取消</el-button>
					<el-button type="primary" @click="preserveBtn">
						提交
					</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		deviceCateListInfo,
		deviceCateInfoAdd,
		deviceCateInfoDel
	} from "@/api/deviceManagement/deviceManagement.js"
	import {
		ElMessage
	} from 'element-plus'
	export default {
		data() {
			return {
				dialogaddArrange: false,
				form: {
					name: "",
					region1: "",
					region2: "",
					keyword: "",
					remarks: ""
				},
				arrangeTabList: [],
				arrangePageList: "",
				pageNo: 1,
				total: 0,
				powerListName: []
			}
		},
		mounted() {
			var list = JSON.parse(sessionStorage.getItem('menuArrayRegulate'));
			console.log(list, 'listlist');
			var nameList = [];

			for (var i = 0; i < list.length; i++) {
				if (list[i].name == "设备管理") {
					for (var j = 0; j < list[i].children.length; j++) {

						if (list[i].children[j].name == "设备应用分类") {
							nameList = list[i].children[j].children;
						}
					}
				}
			}

			if (nameList != undefined) {
				for (var i = 0; i < nameList.length; i++) {
					this.powerListName.push(nameList[i].name)
				}
			}
			console.log(this.powerListName, '最后按钮')
			this.getArrangeTab()
		},
		methods: {

			//页数改变
			handleCurrentChange(pageNo) {
				this.pageNo = pageNo
				this.getArrangeTab()
			},
			// 设备应用分类表格
			async getArrangeTab() {
				const res = await deviceCateListInfo({
					keyword: this.form.keyword,
					page: this.pageNo,
				})
				this.arrangeTabList = res.data.listInfo
				this.total = res.data.allListNumber
			},
			// 搜索
			search() {
				this.getArrangeTab()
			},
			// 重置
			resetBtn() {
				this.pageNo = 1;
				this.form.keyword = ""
				this.getArrangeTab()
			},
			// 表格删除
			async handleClickDel(index, row) {
				const res = await deviceCateInfoDel({
					id: row.id
				})
				ElMessage({
					message: '删除成功',
					type: 'success',
				})
				this.getArrangeTab()
			},
			//表格新增编辑
			handleClickEdit(row) {
				if (row.id) {
					this.dialogTitle = '编辑设备应用'
					this.id = row.id
					this.form = JSON.parse(JSON.stringify(row))
				} else {
					this.dialogTitle = '新增设备应用'
					this.form.name = ""
					this.form.remarks = ""
				}
				this.dialogaddArrange = true
			},
			// 新增保存按钮
			async preserveBtn() {
				if (this.id == '') {
					const res = await deviceCateInfoAdd({
						name: this.form.name,
						remarks: this.form.remarks
					})
				} else {
					const res1 = await deviceCateInfoAdd({
						id: this.id,
						...this.form
					})
				}
				this.dialogaddArrange = false
				this.form.name = ""
				this.form.remarks = ""
				this.getArrangeTab()
			},

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.btns {
		height: 40px;
		width: 100px;
	}

	.leftDiv {
		// margin-left: 28px;

		/deep/.el-form-item__label {
			width: 70px !important;
			// background-color: indianred;
			height: 40px;
			line-height: 40px;
		}
	}

	.bigBox {
		background: #FFFFFF;
		margin: 20px;
		padding: 30px;
	}

	.namePut {
		/deep/.el-input__wrapper {
			width: 360px;
			height: 40px;
		}
	}

	.addDiv {
		border: 1px solid #D4D7E0;
		border-radius: 4px;
		padding: 7px 10px;
		cursor: pointer;

		.iconImg {
			width: 26px;
			height: 26px;
		}

		.youzhi {
			font-size: 18px;
		}
	}

	.agains {
		margin-left: 10px;
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	.searchs {
		margin-left: 10px;
		font-size: 16px;
	}

	.electIpt {
		width: 200px;
		height: 40px;
		margin-left: 10px;
	}

	.fenye {
		float: right;
		margin-top: 40px;
	}

	::v-deep .el-button--primary {
		width: 100px;
		height: 40px;
	}

	/deep/.el-button+.el-button {
		margin-left: 0px;
	}
</style>